import React from "react";
import "./Footer.css";
import Instagram from "../../assets/instagram.png";
import Logo from "../../assets/logo.png";
import LinkedIn from "../../assets/linkedin.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <hr />
      <div className="footer">
        <div className="social-links">
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={Instagram} alt="" />
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={LinkedIn} alt="" />
          </a>
        </div>
        <div className="logo-footer">
          <img src={Logo} alt=""></img>
        </div>
      </div>

      <div className="blur blur-f-1"></div>
      <div className="blur blur-f-2"></div>
    </div>
  );
};

export default Footer;
