import React from "react";
import "./Join.css";
import emailjs from "@emailjs/browser";
import { useRef } from "react";

const Join = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_cmloni7",
        "template_31mg3dm",
        form.current,
        "FbFHcPgcpzboWS298"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="join" id="join-us">
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-text">Ready To</span>
          <span>Shape Your</span>
        </div>
        <div>
          <span>Ideal Body</span>
          <span className="stroke-text">With Us?</span>
        </div>
      </div>

      <div className="right-j">
        <form
          ref={form}
          action=""
          className="form-container"
          onSubmit={sendEmail}
        >
          <input type="text" name="user_name" placeholder="Name" />
          <input type="email" name="user_email" placeholder="Email" />
          <input
            type="tel"
            name="telephone"
            placeholder="Phone"
          />
          <label for="programs">Plan</label>
          <div className="select-program">
            <select id="programs" name="program">
              <option value="basic">Basic Plan - $25</option>
              <option value="premium">Premium Plan - $35</option>
              <option value="pro">Pro Plan - $45</option>
            </select>
          </div>

          <button className="btn btn-join">Join Now</button>
        </form>
      </div>
    </div>
  );
};

export default Join;
